<template>
  <div class="view-home">
      <div class="events-block">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><a href="/new-main">Главная</a></li>
              <li class="breadcrumbs-item"> Мероприятия</li>
            </ul>
          </nav>
          <div class="events-title">
            Мероприятия
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
          <div class="events-section-title">
            Форумы
          </div>
        </div>
        <div class="forums-section">
          <div class="container">
            <router-link to="#" class="all-forums">
              Посмотреть все форумы
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5732 6.55029L3.42681 16.4498" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.6532 3.89222L20.5732 6.55029L17.9152 16.4704" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </router-link>
            <carousel :perPage="1" :loop="true" :autoplay="true" :autoplayTimeout="5000" :navigationEnabled="true"
                      :paginationEnabled="false" class="forum-carousel-block">
              <slide v-for="slide in slides" class="forum-slide-item">
                <div class="forum-slide-title">
                  {{slide.title}}
                </div>
                <div class="forum-slide-text">
                  {{slide.text}}
                </div>
                <div class="forum-slide-details">
                  <div>
                    <span>Даты проведения:</span> {{ slide.date }}
                  </div>
                  <div>
                    <span>Место проведения:</span> {{slide.address}}
                  </div>
                </div>
                <router-link to="#" class="forum-slide-link">
                  Узнать подробнее
                </router-link>
              </slide>
            </carousel>
          </div>

        </div>
        <div class="trainings-section">
          <div class="container">
            <div class="trainings-section-desc">
              <div class="trainings-section-left">
                <div class="trainings-section-title">
                  Семинары, тренинги и повышение квалификации
                </div>
                <div class="trainings-section-text">
                  Повышайте свою квалификацию с нашими семинарами и тренингами! Получите актуальные знания и
                  практические
                  навыки от лучших экспертов. Обменяйтесь опытом с коллегами и создайте полезные связи для успеха в
                  вашей
                  карьере. Независимо от вашего опыта — у нас есть программы, чтобы помочь вам стать лучше в своей
                  области.
                </div>
              </div>
              <div class="trainings-section-right">
                <router-link to="#" class="trainings-section-link">
                  Смотреть все
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 5L17 12.5L9.5 20" stroke="#1090CB" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>

                </router-link>
              </div>
            </div>

            <div class="forums-list">
              <div class="forums-item">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/forums-slide-img.png"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forums-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности школьников»
                </div>
                <div class="forums-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических инструментов, связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forums-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
              <div class="forums-item">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/forums-slide-img.png"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forums-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности школьников»
                </div>
                <div class="forums-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических инструментов, связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forums-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
              <div class="forums-item">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/forums-slide-img.png"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forums-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности школьников»
                </div>
                <div class="forums-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических инструментов, связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forums-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
            </div>

            <div class="trainings-section-desc">
              <div class="trainings-section-left">
                <div class="trainings-section-title">
                  Международные курсы повышения квалификации
                </div>
                <div class="trainings-section-text">
                  Международные курсы повышения квалификации позволяют профессионалам улучшить навыки, обменяться опытом
                  и получить признанные сертификаты. Курсы бывают очные, заочные и онлайн, охватывая различные области.
                  Это способствует карьерному росту и расширению контактов.
                </div>
              </div>
              <div class="trainings-section-right">
                <router-link to="#" class="trainings-section-link">
                  Смотреть все
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 5L17 12.5L9.5 20" stroke="#1090CB" stroke-width="2" stroke-linecap="round"
                          stroke-linejoin="round"/>
                  </svg>

                </router-link>
              </div>
            </div>

            <div class="forums-list">
              <div class="forums-item">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/forums-slide-img-2.png"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forums-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности школьников»
                </div>
                <div class="forums-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических инструментов, связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forums-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
              <div class="forums-item">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/forums-slide-img-2.png"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forums-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности школьников»
                </div>
                <div class="forums-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических инструментов, связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forums-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
              <div class="forums-item">
                <div class="forums-item-img">
                  <el-image
                      style="width: 100%; height: 100%"
                      src="/images/forums-slide-img-2.png"
                      fit="fit"></el-image>
                </div>
                <div class="forums-item-date">
                  Дата проведения: 05.07.2024
                </div>
                <div class="forums-item-title">
                  Республиканский психологический форум-семинар «Диагностика и развитие способностей и одаренности школьников»
                </div>
                <div class="forums-item-text">
                  Цель форума - привлечение отечественных исследователей в области психологии способностей и одаренности детей. На конференции будут обсуждаться современные вызовы, системы психолого-педагогических инструментов, связанные с выявлением, поддержкой и развитием способностей и талантов детей. У экспертов этой области будет возможность обменяться мнениями и поделиться успешными кейсами работы с детьми.
                </div>
                <div class="forums-item-address">
                  Место проведения: г. Астана, пр. Мангилик Ел, 55/20
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>
<script>

export default {
  mounted() {

  },
  filters: {

  },
  data() {
    return {
      slides: [
        {
          title: "Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»",
          text: "Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.",
          date:"05.07.2024 - 07.07.2024 гг",
          address:"г. Астана, пр. Мангилик Ел, 55/20",
        },
        {
          title: "Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»",
          text: "Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.",
          date:"05.07.2024 - 07.07.2024 гг",
          address:"г. Астана, пр. Мангилик Ел, 55/20",
        },
        {
          title: "Международный форум психологов «Актуальные проблемы июня диагностики и развития способностей и одаренности детей и молодежи»",
          text: "Мастер-классы по позитивной психологии, управлению тестированием для специалистов в области образования, развитию коммуникативных навыков детей в малых школах и различиям в индивидуальных особенностях детей также будут проведены в рамках форума.",
          date:"05.07.2024 - 07.07.2024 гг",
          address:"г. Астана, пр. Мангилик Ел, 55/20",
        }
      ],
      currentReview: "",
    }
  }
}
</script>
<style>

.events-block {
  background: linear-gradient(90deg, #32436d 0%, #02c7fa 100%);
  padding-top: 75px;
}

.events-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
  margin-bottom: 15px;
}

.events-title-line {

}

.events-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.6px;
  color: #FFF;
  margin-top: 50px;
  margin-bottom: 40px;
}

.forums-section {
  height: 700px;
  padding: 80px 0;
  background-repeat: no-repeat;
  background-image: url("/images/forum-section-img.png");
  background-size: 100% auto;
  position: relative;
}

.forums-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #32436DA8;
  z-index: 0;
}

.all-forums {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #FFF;
  border-bottom: 1.5px solid #FFF;
  display: inline-flex;
  width: 125px;
  text-transform: uppercase;
  margin-bottom: 21px;
  position: relative;
  z-index: 10;
}

a {
  text-decoration: none;
}

a:hover {
  color: #FFF;
  text-decoration: none;
}

.forum-slide-item {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.forum-slide-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-details {
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #FFF;
  text-shadow: 1px 1px 2px black
}

.forum-slide-details span {
  font-weight: 600;
}

.forum-slide-link {
  display: inline-block;
  background-color: #1090CB;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  padding: 15px 87px;
  border-radius: 13px;
  width: fit-content;
}

.forum-carousel-block .VueCarousel-navigation-prev {
  color: white !important;
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("/images/slider-left.svg");
  font-size: 0;
}

.forum-carousel-block .VueCarousel-navigation-next {
  color: white !important;
  content: "";
  width: 48px;
  height: 48px;
  background-image: url("/images/slider-right.svg");
  font-size: 0;
}

.trainings-section-desc {
  display: flex;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 40px;
}

.trainings-section-left {
  width: 68%;
}

.trainings-section-right {
  width: 30%;
  display: flex;
  justify-content: flex-end;
}

.trainings-section {
  background: #FFF;
  position: relative;
}

.trainings-section::before {
  position: absolute;
  content: "";
  height: 709px;
  width: 77px;
  top: -220px;
  left: 0;
  background-image: url("/images/forums-img-back.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.trainings-section::after {
  position: absolute;
  content: "";
  height: 294px;
  width: 421px;
  top: 680px;
  right: -150px;
  background-image: url("/images/forums-img-back2.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.trainings-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.6px;
  color: #1090CB;
  margin-bottom: 40px;
}

.trainings-section-text {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #585858;

}

.trainings-section-link {
  border: 1.33px solid #1090CB;
  border-radius: 13px;
  font-size: 16px;
  font-weight: 400;
  line-height: 32.69px;
  color: #1090CB;
  padding: 12px 65px;
  display: inline-block;
  height: fit-content;
}

.trainings-section-link:hover {
  color: #1090CB;
}

.forums-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 100px;
}

.forums-item {
  width: 32%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-shadow: 0px 2.74px 105.6px 0px #0000000A;

}

.forums-item-img {
  height: 210px;
  overflow: hidden;
  border-radius: 6px;
}

.forums-item-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #9497A1;
  padding: 0 28px;
}

.forums-item-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #32436D;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 0 28px;
}

.forums-item-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding: 0 28px;
}

.forums-item-address {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #1090CB;
  padding: 0 28px;
  padding-bottom: 28px;
}


</style>
